import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import PropTypes from 'prop-types'
import Header from "../sections/Header/Header"
import CroatianCitizenshipInfo from '../sections/CroatianCitizenshipInfo/CroatianCitizenshipInfo'
import HowCanWeAssistYou from "../sections/HowCanWeAssistYou/HowCanWeAssistYou"
import BecomeCroatianCitizen from '../sections/BecomeCroatianCitizen/BecomeCroatianCitizen'
import ClientReviews from "../sections/ClientReviews/ClientReviews"
import WhatAreTheBenefits from '../sections/WhatAreTheBenefits/WhatAreTheBenefits'
import OurCompany from "../sections/OurCompany/OurCompany"
import headerImg from '../assets/Salona_Consultin_Rovinj_Air.jpg'
import { withTranslation } from "react-i18next"
import i18n from '../i18n'

import SEO from "../components/seo"

import '../styles/main.scss'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
 
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            boxShadow:
              'rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px',
            backgroundColor: 'rgb(255, 68, 0)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            boxShadow:
              'rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px',
            backgroundColor: 'rgb(255, 68, 0)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">{mainpitch.description}</h3>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/products">
                      See all products
                    </Link>
                  </div>
                </div>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Latest stories
                  </h3>
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({t}) => {
  if (i18n.language !== 'en') {
    i18n.changeLanguage('en')
  }
  return (
    <Layout>
      <SEO title={t("TITLE_HOME")} metaT={t("META_HOME")} />
      <Header 
        image={headerImg}
        title={t("HOME_HEADER")}
        subtitle={t("HOME_SUBTITLE")}
        actionTitle={t("HOME_BTN")}
        section={"croatian-citizenship-info"}
      />
      <CroatianCitizenshipInfo/>
      <BecomeCroatianCitizen/>
      <HowCanWeAssistYou/>
      <ClientReviews/>
      <WhatAreTheBenefits/>
      <OurCompany/>
      
    </Layout>
  )
}

export default withTranslation()(IndexPage)
